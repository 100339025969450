import React, {useContext} from 'react'
import DarkmodeContext from '../../../state/darkmodel-context'
import * as styles from './Card.module.css'

export default function Card({className="",children=""}) {
    const {darkMode: isDark} = useContext(DarkmodeContext);


    return (
        <div className={`${styles.card} ${className} ${isDark?styles.dark: ''}`}>
            {children}
        </div>
    )
}
